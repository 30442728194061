import { FunctionComponent, useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import { IBiggestWinStats } from '@api/types/stats';

interface Props {
  biggestWinStats: null | IBiggestWinStats;
}

const SectionSix: FunctionComponent<Props> = ({ biggestWinStats }: Props) => {
  const [didViewCountUp, setDidViewCountUp] = useState(false);

  const onVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };

  if (!biggestWinStats) {
    return null;
  }

  return (
    <section id="section-sixth" className="sixth-screen">
      <div className="container-fluid">
        <div className="row justify-content-center mb-300">
          <div className="col-12 col-lg-12 text-left">
            <div className="currently-holding position-relative">
              <div className="fs-510 w-100 d-flex flex-column u-upper text-success1">
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="1.5s"
                >
                  YOUR
                </span>
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.75s"
                  data-wow-duration="2s"
                >
                  BIGGEST
                </span>
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="1s"
                  data-wow-duration="2.5s"
                >
                  WIN
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mb-0">
          <div className="col-12 col-lg-11 text-center">
            {biggestWinStats?.tokenImage && (
              <div className="d-flex flex-column justify-content-center align-items-end">
                <picture
                  className="size-220 m-0 p-0 mr-16 mbn-50 zover wow fadeInRight"
                  data-wow-iteration="1"
                  data-wow-delay="0.25s"
                  data-wow-duration="1.5s"
                >
                  <img
                    src={biggestWinStats?.tokenImage}
                    alt=""
                    className="img-fluid styled-image rotate30n"
                  />
                </picture>
              </div>
            )}
            <h1 className="fs-220 text-white d-flex flex-column u-upper">
              <span
                className="wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.5s"
                data-wow-duration="1.5s"
              >
                {biggestWinStats?.collectionName}
              </span>
              <span
                className="wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.75s"
                data-wow-duration="2s"
              >
                #{biggestWinStats?.tokenId}
              </span>
            </h1>
          </div>
        </div>
        <div className="row justify-content-center mb-250">
          <div className="col-12 col-lg-7 text-center">
            <div className="d-flex w-95 blb flex-column justify-content-center align-items-end">
              <div className="transitionLine"></div>
              <div
                className="d-flex flex-column fs-40 pt-5 text-left text-success1 wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.5s"
                data-wow-duration="1.5s"
              >
                <span className="w-100">
                  {biggestWinStats?.amount && biggestWinStats?.amount > 0 ? (
                    <>bought for {biggestWinStats?.amount}ETH</>
                  ) : (
                    <>Received for free</>
                  )}
                </span>
                <span className="w-100">on {biggestWinStats?.boughtOn}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row d-flex align-items-center">
          {biggestWinStats?.tokenImage && (
            <div className="col-12 col-lg-5 col-md-5 text-right">
              <picture
                className="duck-graphic2 wow rollIn"
                data-wow-iteration="1"
                data-wow-delay="0.5s"
                data-wow-duration="1.5s"
              >
                <img
                  src={biggestWinStats?.tokenImage}
                  alt=""
                  className="w-100 img-fluid styled-image opacity2 rotate30"
                />
              </picture>
            </div>
          )}
          <div className="col-12 col-lg-6 col-md-6 text-right">
            <div className="w-100 mb-250">
              <div className="fs-100 u-upper d-flex flex-column">
                <span
                  className="text-success1 wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="1.5s"
                >
                  {biggestWinStats?.isSold && 'You held it for'}
                  {!biggestWinStats?.isSold && 'You’ve BEEN'}
                </span>
                {!biggestWinStats?.isSold && (
                  <span
                    className="text-success1 wow fadeInUp"
                    data-wow-iteration="1"
                    data-wow-delay="0.75s"
                    data-wow-duration="2s"
                  >
                    HOLDING it for
                  </span>
                )}
                <span
                  className="fs-210 text-white wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="1s"
                  data-wow-duration="2.5s"
                >
                  <VisibilitySensor
                    onChange={onVisibilityChange}
                    offset={{
                      top: 10,
                    }}
                    delayedCall
                  >
                    <CountUp
                      start={0}
                      decimals={2}
                      end={
                        didViewCountUp
                          ? biggestWinStats
                            ? parseFloat(biggestWinStats.heldDays)
                            : 0
                          : 0
                      }
                      duration={1}
                      className="text-white"
                      redraw={true}
                    />
                  </VisibilitySensor>{' '}
                  Days
                </span>
              </div>
            </div>

            <div className="w-100">
              {!biggestWinStats?.isSold && (
                <div className="fs-100 u-upper d-flex flex-column">
                  <span
                    className="text-success1 wow fadeInUp"
                    data-wow-iteration="1"
                    data-wow-delay="0.5s"
                    data-wow-duration="1.5s"
                  >
                    THE CURRENT
                  </span>
                  <span
                    className="text-success1 wow fadeInUp"
                    data-wow-iteration="1"
                    data-wow-delay="0.75s"
                    data-wow-duration="2s"
                  >
                    FLOOR IS
                  </span>
                  <span
                    className="fs-210 text-white wow fadeInUp"
                    data-wow-iteration="1"
                    data-wow-delay="1s"
                    data-wow-duration="2.5s"
                  >
                    {biggestWinStats?.collectionCurrentFloorPrice} ETH
                  </span>
                </div>
              )}
              {biggestWinStats?.isSold && (
                <div className="fs-100 u-upper d-flex flex-column">
                  {biggestWinStats.isSold && biggestWinStats.soldStats.soldPrice > 0 && (
                    <>
                      <span
                        className="text-success1 wow fadeInUp"
                        data-wow-iteration="1"
                        data-wow-delay="0.5s"
                        data-wow-duration="1.5s"
                      >
                        And sold it
                      </span>
                      <span
                        className="fs-210 text-white wow fadeInUp"
                        data-wow-iteration="1"
                        data-wow-delay="1s"
                        data-wow-duration="2.5s"
                      >
                        {biggestWinStats.soldStats.soldPrice} ETH
                      </span>
                    </>
                  )}
                  {biggestWinStats.isSold && biggestWinStats.soldStats.soldPrice === 0 && (
                    <>
                      <span
                        className="text-success1 wow fadeInUp"
                        data-wow-iteration="1"
                        data-wow-delay="0.5s"
                        data-wow-duration="1.5s"
                      >
                        And transferred it
                      </span>
                    </>
                  )}
                  <span
                    className="fs-150 text-white wow fadeInUp"
                    data-wow-iteration="1"
                    data-wow-delay="1s"
                    data-wow-duration="2.5s"
                  >
                    on {biggestWinStats.soldStats.soldDate}
                  </span>
                </div>
              )}
            </div>

            {biggestWinStats.isSold && biggestWinStats.soldStats.soldPrice > 0 && (
              <div className="w-100">
                <div className="fs-100 u-upper d-flex flex-column">
                  <span
                    className="text-success1 wow fadeInUp"
                    data-wow-iteration="1"
                    data-wow-delay="0.5s"
                    data-wow-duration="1.5s"
                  >
                    You made
                  </span>
                  <span
                    className="text-success1 wow fadeInUp"
                    data-wow-iteration="1"
                    data-wow-delay="0.75s"
                    data-wow-duration="2s"
                  >
                    a profit of
                  </span>
                  <span
                    className="fs-210 text-white wow fadeInUp"
                    data-wow-iteration="1"
                    data-wow-delay="1s"
                    data-wow-duration="2.5s"
                  >
                    {biggestWinStats.soldStats.profit} ETH
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionSix;
