import { FunctionComponent, ElementType, useEffect, ComponentType } from 'react';
import { Route, RouteProps, RouteComponentProps, StaticContext } from 'react-router';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';

import appConfig from '@config/config';

//Common Pages
import UnauthenticatedLayout from '@layouts/UnauthenticatedLayout';

interface AppRouteProps extends RouteProps {
  component: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ComponentType<RouteComponentProps<any, StaticContext, unknown>>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ComponentType<any>;
  layout?: ElementType;
  accessControl?: string;
}

const AppRoute: FunctionComponent<AppRouteProps> = ({
  component: Component,
  layout: ComponentLayout = UnauthenticatedLayout,
  accessControl = 'any',
  ...rest
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.body.className = 'user-loggedin';
  }, [pathname]);

  // initialize Google Analytic;
  ReactGA.initialize(appConfig.GA_TRACKING_ID);

  ReactGA.send({ hitType: 'pageview', page: pathname });

  return (
    <Route
      {...rest}
      render={(props) => (
        <ComponentLayout {...props}>
          <Component {...props} />
        </ComponentLayout>
      )}
    />
  );
};

AppRoute.propTypes = {
  children: PropTypes.element,
  layout: PropTypes.any,
  component: PropTypes.any,
  accessControl: PropTypes.string,
};

export default AppRoute;
