import { FunctionComponent } from 'react';
import { LazyImage } from 'react-lazy-images';

import DefaultImagePlaceHolder from '@assets/images/logo512.png';

interface Props {
  imageUrl: string;
  imageToken: string;
}

const LazyImageComponent: FunctionComponent<Props> = ({
  imageUrl,
  imageToken,
}: Props) => {
  let imageWithGateway = imageUrl.replace('ipfs.io', 'nftwrapped.mypinata.cloud');
  imageWithGateway = imageWithGateway.replace(
    'cloudflare-ipfs.com',
    'nftwrapped.mypinata.cloud'
  );

  return (
    <LazyImage
      src={imageWithGateway}
      alt={imageToken}
      placeholder={({ imageProps, ref }) => (
        <img ref={ref} src={DefaultImagePlaceHolder} alt={imageProps.alt} />
      )}
      actual={({ imageProps }) => <img {...imageProps} className="img-fluid" />}
      error={() => <img src={DefaultImagePlaceHolder} alt="Metalink" />}
    />
  );
};

export default LazyImageComponent;
