import { FunctionComponent, useState } from 'react';
import { IMintImageObj } from '@api/types/stats';

interface Props {
  mintImageObj: null | IMintImageObj;
  mintHandler: () => void;
  mintUsingSOSHandler: () => void;
  showLoader: boolean;
  mintWalletAddress: string;
}

const SectionEleven: FunctionComponent<Props> = ({
  mintImageObj,
  mintHandler,
  mintUsingSOSHandler,
  showLoader,
  mintWalletAddress,
}: Props) => {
  const [showMintingButtons] = useState<boolean>(false);

  return (
    <section id="section-eleventh" className="eleventh-screen pb-0">
      <div className="container">
        <div className="section-eleventh-graphic"></div>
        <div className="row justify-content-center mb-400">
          <div className="col-12 col-lg-12 text-center">
            <div className="currently-holding position-relative">
              <h1 className="fs-150 text-white d-flex flex-column u-upper">
                <span
                  className="w-100 text-center wow fadeInLeft"
                  data-wow-iteration="1"
                  data-wow-delay="0.25s"
                  data-wow-duration="1s"
                >
                  Thanks for
                </span>
                <span
                  className="wow fadeInRight"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="1.25s"
                >
                  joining us for
                </span>
                <span
                  className="w-100 text-center wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.75s"
                  data-wow-duration="1.5s"
                >
                  <span className="fs-150-stroke">WRAPPED</span> 2021...
                </span>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div
          className="section-eleventh-graphic2 wow fadeInRight"
          data-wow-iteration="1"
          data-wow-delay="0.25s"
          data-wow-duration="1s"
        ></div>
        {mintImageObj && (
          <>
            <div className="row mb-400">
              <div className="col-12">
                <div className="fs-150 d-flex flex-column u-upper text-center fs-100 text-white">
                  <span
                    className="wow fadeInLeft"
                    data-wow-iteration="1"
                    data-wow-delay="0.25s"
                    data-wow-duration="1s"
                  >
                    Don’t Forget To Save
                  </span>
                  <span
                    className="wow fadeInRight"
                    data-wow-iteration="1"
                    data-wow-delay="0.5s"
                    data-wow-duration="1.25s"
                  >
                    This PIECE OF NFT
                  </span>
                  <span
                    className="wow fadeInUp"
                    data-wow-iteration="1"
                    data-wow-delay="0.75s"
                    data-wow-duration="1.5s"
                  >
                    HISTORY
                  </span>
                </div>
              </div>
            </div>

            <div className="row mb-30">
              <div className="col-12">
                <div className="w-100 d-flex flex-column u-upper text-center fs-240 SuperluminaBold text-offwhite">
                  <span
                    className="wow fadeInUp"
                    data-wow-iteration="1"
                    data-wow-delay="0.25s"
                    data-wow-duration="1s"
                  >
                    Right
                  </span>
                  <span
                    className="wow fadeInUp"
                    data-wow-iteration="1"
                    data-wow-delay="0.5s"
                    data-wow-duration="1.25s"
                  >
                    Click
                  </span>
                  <span
                    className="wow fadeInUp"
                    data-wow-iteration="1"
                    data-wow-delay="0.75s"
                    data-wow-duration="1.5s"
                  >
                    Save As
                  </span>
                </div>
              </div>
            </div>

            <div className="row mb-30">
              <div className="col-12">
                <div className="w-100 d-flex flex-column u-upper text-center fs-500 SuperluminaBlack text-offwhite">
                  <span
                    className="fs-500-stroke wow zoomIn"
                    data-wow-iteration="1"
                    data-wow-delay="0.95s"
                    data-wow-duration="1.75s"
                  >
                    OR
                  </span>
                </div>
              </div>
            </div>

            <div className="row mb-100">
              <div className="col-12">
                <div
                  className="w-100 d-flex flex-column u-upper text-center fs-240 SuperluminaBold text-offwhite wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="1.15s"
                  data-wow-duration="2s"
                >
                  <span>Mint</span>
                </div>
              </div>
            </div>

            {mintImageObj?.mintImageLocation && (
              <div className="row justify-content-center mb-40">
                <div className="col-12 col-lg-4 col-md-7 col-sm-12 text-center custom-col">
                  <figure className="m-0 p-0 mx-auto">
                    <img
                      src={mintImageObj?.mintImageLocation}
                      alt="NFT Wrapped"
                      className="img-fluid img-glow radius-40 wow zoomIn"
                      data-wow-iteration="1"
                      data-wow-delay="1.25s"
                      data-wow-duration="2.25s"
                    />
                  </figure>
                </div>
              </div>
            )}

            <div className="row justify-content-center mb-175">
              <div className="col-12 col-lg-4 col-md-7 col-sm-12 text-center custom-col">
                <a
                  type="button"
                  className="button button-secondary button-lg button-block mb-40 red button-mintingover"
                  data-wow-iteration="1"
                  data-wow-delay="1.5s"
                  data-wow-duration="2.5s"
                  href="https://opensea.io/collection/nftwrapped-v3"
                  target="_blank"
                  rel="noreferrer"
                >
                  MINTING OVER
                </a>
                {/* {!mintWalletAddress && (
                  <button
                    type="button"
                    className="button button-secondary button-lg button-block mb-40"
                    data-wow-iteration="1"
                    data-wow-delay="1.5s"
                    data-wow-duration="2.5s"
                    onClick={mintHandler}
                  >
                    CONNECT WALLET TO MINT
                  </button>
                )} */}
                {/* {mintWalletAddress && !showMintingButtons && (
                  <button
                    type="button"
                    // className="button button-secondary button-lg button-block mb-40 wow fadeInUp"
                    className="button button-secondary button-lg button-block mb-40"
                    data-wow-iteration="1"
                    data-wow-delay="1.5s"
                    data-wow-duration="2.5s"
                    onClick={() => setShowMintingButtons(true)}
                  >
                    MINT
                  </button>
                  
                )} */}
                {mintWalletAddress && showMintingButtons && (
                  <>
                    {showLoader && (
                      <button
                        type="button"
                        className="button button-secondary button-lg button-block mb-40"
                        data-wow-iteration="1"
                        data-wow-delay="1.5s"
                        data-wow-duration="2.5s"
                      >
                        Please wait...
                      </button>
                    )}
                    {!showLoader && (
                      <>
                        <button
                          type="button"
                          // className="button button-secondary button-lg button-block mb-40 wow fadeInUp"
                          className="button button-secondary button-lg button-block mb-40"
                          data-wow-iteration="1"
                          data-wow-delay="1.5s"
                          data-wow-duration="2.5s"
                          onClick={mintHandler}
                        >
                          MINT WITH ETH
                        </button>

                        <button
                          type="button"
                          // className="button button-secondary button-lg button-block mb-40 wow fadeInUp"
                          className="button button-secondary button-lg button-block mb-40"
                          data-wow-iteration="1"
                          data-wow-delay="1.5s"
                          data-wow-duration="2.5s"
                          onClick={mintUsingSOSHandler}
                        >
                          MINT WITH SOS
                        </button>
                      </>
                    )}
                  </>
                )}

                <a
                  href={`http://www.twitter.com/share?text=${encodeURIComponent(
                    'I just got my personalized year end NFT recap 🔮 A must do if you ask me! Thanks @metalinklabs for the amazing experience! See your own best, worst, and most insane NFT stats of 2021 here >> https://metalink.com/wrapped 👀 #NFTWrapped2021'
                  )}`}
                  // className="button button-primary button-lg button-block SuperluminaSemiBold wow fadeInUp"
                  className="button button-primary button-lg button-block SuperluminaSemiBold"
                  data-wow-iteration="1"
                  data-wow-delay="1.75s"
                  data-wow-duration="2.75s"
                  target="_blank"
                  rel="noreferrer"
                >
                  SHARE ON TWITTER
                </a>
                <div className="mint-price mt-100">
                  🐣 Mint Price: 0.05ETH <br />
                </div>
                <div className="mint-price mt-20">Mint Price: 75,000,000SOS</div>
              </div>
            </div>
          </>
        )}
        <div className="row justify-content-center mb-0">
          <div className="col-12 col-lg-10 text-center">
            <div className="fs-205 d-flex flex-column u-upper text-center SuperluminaBlack text-offwhite">
              <span
                className="wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.25s"
                data-wow-duration="1s"
              >
                Your
              </span>
              <span
                className="wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.5s"
                data-wow-duration="1.25s"
              >
                Journey
              </span>
              <span
                className="wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.75s"
                data-wow-duration="1.5s"
              >
                Isn’t
              </span>
              <span
                className="wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="1"
                data-wow-duration="1.75s"
              >
                Over...
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className="enter wow fadeInUp"
        data-wow-iteration="1"
        data-wow-delay="1"
        data-wow-duration="1.75s"
      >
        <div className="enter-inner">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-6 col-md-7 col-sm-12 text-center custom-col">
                <a
                  href="https://metalink.com/today"
                  className="button button-primary button-lg button-block u-upper SuperluminaSemiBold"
                  target="_blank"
                  rel="noreferrer"
                >
                  ENTER THE METALINK
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionEleven;
