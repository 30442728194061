import { FunctionComponent, /* useEffect,*/ useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import { ITotalStats, INftOwnedStats } from '@api/types/stats';
import LazyImageComponent from '@components/LazyImageComponent';
// import { CheckByImageRequest } from '@utils/imageCheck';

interface Props {
  totalStats: ITotalStats;
  nftOwnedStats: Array<INftOwnedStats>;
}

const SectionThree: FunctionComponent<Props> = ({ totalStats, nftOwnedStats }: Props) => {
  const [didViewCountUp, setDidViewCountUp] = useState<boolean>(false);

  const onVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };

  let totalRowRender = parseInt(`${window.outerWidth / 190}`);
  totalRowRender = totalRowRender <= 2 ? 3 : totalRowRender;

  const appendRows = 3;
  let imageIndex = 0;

  // useEffect(() => {
  //   const test = async () => {
  //     const isImage = await CheckByImageRequest(
  //       'https://badbunniesnft.com/art/RiF9IaRxAYWnh8jx1Hnb.png'
  //     );
  //     console.log(isImage);
  //   };
  //   test();
  // }, []);

  return (
    <section id="section-three" className="third-screen">
      <div className="container">
        <div className="row justify-content-center mb-135">
          <div className="col-12 col-lg-12 text-center">
            <h1 className="fs-180 text-white d-flex flex-column u-upper">
              <span
                className="w-100 text-center wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.3s"
                data-wow-duration="2s"
              >
                WHILE EVERYONE WAS
              </span>
              <span
                className="w-100 text-center wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.4s"
                data-wow-duration="2.5s"
              >
                TRYING TO FIGURE OUT
              </span>
              <span
                className="w-100 text-center wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.5s"
                data-wow-duration="3s"
              >
                WHAT NFTS WERE...
              </span>
            </h1>
          </div>
        </div>

        <div className="row justify-content-center mb-175">
          <div className="col-12 col-lg-12 text-center">
            <h1
              className="fs-120 text-white d-flex flex-column u-upper wow fadeInUp"
              data-wow-iteration="1"
              data-wow-delay="0.55s"
              data-wow-duration="3.25s"
            >
              <span className="w-100 text-center">You Were ALREADY APING</span>
            </h1>
          </div>
        </div>
      </div>
      {nftOwnedStats.length > 0 && (
        <div className="container-fluid">
          <div className="row mb-250">
            <div className="col-12 mb-20">
              {Array.from(Array(appendRows).keys()).map((obj) => (
                <div
                  className={`hr-scroll wow ${obj === 1 ? `fadeInRight` : `fadeInLeft`}`}
                  data-wow-iteration="1"
                  data-wow-delay="0.3"
                  data-wow-duration="2s"
                  key={obj}
                >
                  <ul className="hr-scroll-lists">
                    {Array.from(Array(totalRowRender).keys()).map((_obj, itemKey) => {
                      let imageUrl = nftOwnedStats[imageIndex].tokenImage
                        ? nftOwnedStats[imageIndex].tokenImage
                        : nftOwnedStats[0].tokenImage;
                      const tokenId = nftOwnedStats[imageIndex].tokenId
                        ? nftOwnedStats[imageIndex].tokenId
                        : nftOwnedStats[0].tokenId;
                      imageIndex++;
                      if (imageIndex >= nftOwnedStats.length) {
                        imageIndex = 0;
                      }

                      if (imageUrl.includes('data:image')) {
                        return null;
                      }
                      imageUrl = imageUrl.replace('http://', 'https://');

                      return (
                        <li key={itemKey}>
                          <picture className="size-170">
                            {imageUrl.includes('data:image') ? (
                              <img src={imageUrl} alt={tokenId} className="img-fluid" />
                            ) : (
                              <>
                                <LazyImageComponent
                                  imageUrl={imageUrl}
                                  imageToken={tokenId}
                                />
                                {/* <img src={imageUrl} alt={tokenId} className="img-fluid" /> */}
                              </>
                            )}
                          </picture>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="container">
        <div className="row justify-content-center mb-200">
          <div className="col-12 col-lg-12 text-center">
            <div
              className="fancy-header text-center wow fadeInUp"
              data-wow-iteration="1"
              data-wow-delay="0.3s"
              data-wow-duration="2s"
            >
              <div className="fancy-header-title fs-330 text-white" data-title="IN 2021">
                IN 2021
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mb-150">
          <div className="col-12 col-lg-12 text-center">
            <div className="d-flex align-items-center justify-content-center flex-row text-center">
              <div
                className="fs-300 SuperluminaBlack text-white mr-5 wow fadeInLeft"
                data-wow-iteration="1"
                data-wow-delay="0.3s"
                data-wow-duration="2s"
              >
                <VisibilitySensor
                  onChange={onVisibilityChange}
                  offset={{
                    top: 10,
                  }}
                  delayedCall
                >
                  <CountUp
                    start={0}
                    end={didViewCountUp ? totalStats.boughtCount : 0}
                    duration={4}
                    className="text-white"
                    redraw={true}
                  />
                </VisibilitySensor>
              </div>
              <div
                className="d-flex flex-column text-left fs-120 wow fadeInRight"
                data-wow-iteration="1"
                data-wow-delay="0.35s"
                data-wow-duration="2.25s"
              >
                <span>NFTs</span>
                <span>BOUGHT</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mb-150">
          <div className="col-12 col-lg-12 text-center">
            <div className="d-flex align-items-center justify-content-center flex-row text-center">
              <div
                className="d-flex flex-column text-left fs-120 wow fadeInLeft"
                data-wow-iteration="1"
                data-wow-delay="0.325s"
                data-wow-duration="2.5s"
              >
                <span>NFTs</span>
                <span>SOLD</span>
              </div>
              <div
                className="fs-300 SuperluminaBlack text-white ml-5 wow fadeInRight"
                data-wow-iteration="1"
                data-wow-delay="0.35s"
                data-wow-duration="2.75s"
              >
                <VisibilitySensor
                  onChange={onVisibilityChange}
                  offset={{
                    top: 10,
                  }}
                  delayedCall
                >
                  <CountUp
                    start={0}
                    end={didViewCountUp ? totalStats.soldCount : 0}
                    duration={2}
                    className="text-white"
                    redraw={true}
                  />
                </VisibilitySensor>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mb-150">
          <div className="col-12 col-lg-12 text-center">
            <div className="d-flex align-items-center justify-content-center flex-row text-center">
              <div
                className="fs-300 SuperluminaBlack text-white mr-5 wow fadeInLeft"
                data-wow-iteration="1"
                data-wow-delay="0.35s"
                data-wow-duration="2.75s"
              >
                <VisibilitySensor
                  onChange={onVisibilityChange}
                  offset={{
                    top: 10,
                  }}
                  delayedCall
                >
                  <CountUp
                    start={0}
                    end={didViewCountUp ? totalStats.receivedCount : 0}
                    duration={2}
                    className="text-white"
                    redraw={true}
                  />
                </VisibilitySensor>
              </div>
              <div
                className="d-flex flex-column text-left fs-120 wow fadeInRight"
                data-wow-iteration="1"
                data-wow-delay="0.35s"
                data-wow-duration="2.25s"
              >
                <span>NFTs</span>
                <span>RECEIVED</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionThree;
