import { FunctionComponent } from 'react';

import { IBiggestLossStats } from '@api/types/stats';

import Outline from '@assets/images/outline.png';

interface Props {
  biggestLossStats: null | IBiggestLossStats;
}

const SectionSeven: FunctionComponent<Props> = () => {
  return (
    <section id="section-seventh" className="seventh-screen">
      <div className="container-fluid">
        <div className="row justify-content-center mb-800">
          <div className="col-12 col-lg-12 text-left">
            <div className="currently-holding position-relative">
              <div className="fs-510 w-100 d-flex flex-column u-upper text-warning1 text-right">
                <span>YOUR</span>
                <span>BIGGEST</span>
                <span>L...</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mb-800">
          <div className="col-12 col-lg-11 text-center">
            <h1 className="fs-220 text-white d-flex flex-column u-upper">
              <span>JK...</span>
            </h1>
          </div>
        </div>

        <div className="row justify-content-center mbn-70">
          <div className="col-12 col-lg-11 text-center zover">
            <h1 className="fs-220 text-white d-flex flex-column u-upper">
              <span>WE KEEP THOSE</span>
              <span>ON “HIDDEN”...</span>
            </h1>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 text-right">
            <picture>
              <img src={Outline} alt="Outline" className="w-100 img-fluid" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionSeven;
