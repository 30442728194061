import { FunctionComponent, useState, useEffect } from 'react';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';

import WalletStatApi from '@api/WalletStatApi';
import { IMyStatsResponse } from '@api/types/stats';
import { getCatchError } from '@helpers/ErrorHandler';
import { connectWallet, mintNFT, mintNFTUsingSOS } from '@utils/interact';

// Import Static Sections
import SectionOne from './partials/section-one';
import SectionTwo from './partials/section-two';
import SectionThree from './partials/section-three';
import SectionFour from './partials/section-four';
import SectionFive from './partials/section-five';
import SectionSix from './partials/section-six';
import SectionSeven from './partials/section-seven';
import SectionEight from './partials/section-eight';
import SectionNine from './partials/section-nine';
import SectionTenth from './partials/section-ten';
import SectionEleven from './partials/section-eleven';

// Import Static Images
import Logo from '@assets/images/logo.png';

interface Props extends RouteComponentProps {}

const Homepage: FunctionComponent<Props> = (props: Props) => {
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [mintWalletAddress, setMintWalletAddress] = useState<string>('');
  const [checkWalletConnectStatus, setCheckWalletConnectStatus] = useState<boolean>(true);
  const [_status, setStatus] = useState<string>('');
  const [mintPrice, _setMintPrice] = useState<number>(0.05);
  const [mintTokensSOS, _setMintTokensSOS] = useState<number>(75000000);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showSecondLoaderSection, setShowSecondLoaderSection] = useState<boolean>(false);
  const [showDetailDiv, setShowDetailDiv] = useState<boolean>(false);
  const [callMintHandler, setCallMintHandler] = useState<boolean>(false);
  const [walletStats, setWalletStats] = useState<null | IMyStatsResponse>(null);

  // const getMintImageUrl = async (imageUrl: string, key: string) => {
  //   let returnUrl = imageUrl;

  //   const imageSplitArray = imageUrl.split('/');
  //   const keySplitArray = key.split('/');

  //   if (imageSplitArray.length === 5) delete imageSplitArray[4];

  //   returnUrl = imageSplitArray.join('/');
  //   returnUrl = returnUrl + keySplitArray[1];

  //   return returnUrl;
  // };

  const mintUsingSOSHandler = async () => {
    try {
      setStatus('');
      setShowLoader(true);

      const response = await WalletStatApi.mintNft(
        walletStats?.mintImageDetails?.mintImageKey ?? '',
        mintWalletAddress
      );

      if (response.data.tokenId) {
        const { status, success } = await mintNFTUsingSOS(
          response.data.tokenId,
          mintTokensSOS
        );
        setStatus(status);
        if (!success) {
          await WalletStatApi.deleteMintNft(mintWalletAddress);
        }
      }
    } catch (err: any) {
      setStatus(getCatchError(err)[0]);
    } finally {
      setShowLoader(false);
    }
  };

  const mintHandler = async () => {
    try {
      if (!mintWalletAddress) {
        setStatus('');
        const walletResponse = await connectWallet();
        setMintWalletAddress(walletResponse.address);
        // setCallMintHandler(true);
        setStatus(walletResponse.status);
        return;
      }
      setStatus('');
      setShowLoader(true);

      const response = await WalletStatApi.mintNft(
        walletStats?.mintImageDetails?.mintImageKey ?? '',
        mintWalletAddress
      );

      if (response.data.tokenId) {
        // const walletStatsObj = walletStats;
        // if (walletStatsObj && walletStatsObj.mintImageDetails) {
        //   walletStatsObj.mintImageDetails.mintImageLocation = await getMintImageUrl(
        //     walletStatsObj.mintImageDetails.mintImageLocation,
        //     walletStatsObj.mintImageDetails.mintImageKey
        //   );
        // }
        // setWalletStats(walletStatsObj);
        setStatus('');
        const { status, success } = await mintNFT(response.data.tokenId, mintPrice);
        setStatus(status);
        if (!success) {
          await WalletStatApi.deleteMintNft(mintWalletAddress);
        }
      }

      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setStatus(getCatchError(err)[0]);
    } finally {
      setShowLoader(false);
      setCallMintHandler(false);
    }
  };

  const walletAddressHandler = (value: string, isToken?: boolean) => {
    if (value) {
      fetchWalletStats(value, isToken);
    } else {
      setWalletAddress(value);
      setMintWalletAddress(value);
      setShowDetailDiv(false);
    }
  };

  const setTimerFunction = async () => {
    setTimeout(() => {
      setShowSecondLoaderSection(true);
    }, 8000);
  };

  const fetchWalletStats = async (value: string, isToken?: boolean) => {
    try {
      setStatus('');
      setShowLoader(true);
      let response;
      setTimerFunction();

      if (isToken) {
        response = await WalletStatApi.getWalletStatsByToken(value);
      } else {
        response = await WalletStatApi.getWalletStats(value);
      }

      setWalletAddress(value);
      if (!isToken) {
        setMintWalletAddress(value);
      }
      setWalletStats(response.data);
      setShowLoader(false);

      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setStatus(getCatchError(err)[0]);
    } finally {
      setShowLoader(false);
    }
  };

  // const addWalletListener = async (isMint?: boolean) => {
  //   if (window.ethereum) {
  //     window.ethereum.on('accountsChanged', (accounts: any) => {
  //       setStatus('');
  //       if (accounts.length > 0) {
  //         if (!isMint) {
  //           setWalletAddress(accounts[0]);
  //         }
  //         setMintWalletAddress(accounts[0]);
  //         setStatus('');
  //         if (isMint) {
  //           mintHandler();
  //         }
  //       } else {
  //         setWalletAddress('');
  //         setMintWalletAddress('');
  //         setStatus('🦊 Connect to Metamask using the top right button.');
  //       }
  //     });
  //   } else {
  //     setWalletAddress('');
  //     setMintWalletAddress('');
  //     setStatus(
  //       '🦊  You must install Metamask, a virtual Ethereum wallet, in your browser.'
  //     );
  //   }
  // };

  const connectWalletPressed = async (isAdd: boolean) => {
    if (isAdd) {
      setStatus('');
      const walletResponse = await connectWallet();
      walletAddressHandler(walletResponse.address);
      setStatus(walletResponse.status);
    } else {
      walletAddressHandler('');
    }
  };

  useEffect(() => {
    // get query string to obj;
    const stringQuery: { wrappedToken?: string | null } = queryString.parse(
      props.location.search
    );

    if (!stringQuery.wrappedToken) {
      window.location.href = `http://metalink.com/wrapped`;
    }

    if (stringQuery.wrappedToken && checkWalletConnectStatus) {
      setCheckWalletConnectStatus(false);
      walletAddressHandler(stringQuery.wrappedToken, true);
      setStatus('');
    }
  }, [checkWalletConnectStatus]);

  useEffect(() => {
    if (_status) {
      // toast.error(_status);
      toast.dismiss();
      toast(() => <div dangerouslySetInnerHTML={{ __html: _status }}></div>);
    }
  }, [_status]);

  useEffect(() => {
    if (mintWalletAddress && callMintHandler) {
      mintHandler();
    }
  }, [mintWalletAddress, callMintHandler]);

  return (
    <div className="c-wrapper c-wrapper-md no-padding">
      <div className="app-logo">
        <img src={Logo} alt=""></img>
      </div>
      {!showDetailDiv && (
        <SectionOne
          walletAddressHandler={connectWalletPressed}
          setShowDetailDiv={setShowDetailDiv}
          walletAddress={walletAddress}
          showLoader={showLoader}
          firstTransactionStats={walletStats?.firstTransactionStats}
          showSecondLoaderSection={showSecondLoaderSection}
        />
      )}

      {walletAddress && walletStats?.firstTransactionStats && showDetailDiv && (
        <>
          <SectionTwo firstTransactionStats={walletStats?.firstTransactionStats} />
          <SectionThree
            totalStats={walletStats?.totalStats}
            nftOwnedStats={walletStats.nftOwnedStats}
          />
          <SectionFour
            totalStats={walletStats?.totalStats}
            totalGasStats={walletStats.totalGasStats}
          />
          <SectionFive collectionStats={walletStats?.collectionStats} />
          <SectionSix biggestWinStats={walletStats.biggestWinStats} />
          <SectionSeven biggestLossStats={walletStats.biggestLossStats} />
          <SectionEight quickestFlipStats={walletStats.quickestFlipStats} />
          <SectionNine longestHoldStats={walletStats.longestHodlStats} />
          <SectionTenth portfolioStats={walletStats.totalPortfolioStats} />
          <SectionEleven
            mintImageObj={walletStats.mintImageDetails}
            mintWalletAddress={mintWalletAddress}
            mintHandler={mintHandler}
            mintUsingSOSHandler={mintUsingSOSHandler}
            showLoader={showLoader}
          />
        </>
      )}
    </div>
  );
};

export default Homepage;
