import { FunctionComponent, useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import { ICollectionStats } from '@api/types/stats';

interface Props {
  collectionStats: ICollectionStats;
}

const SectionFive: FunctionComponent<Props> = ({ collectionStats }: Props) => {
  const getConfigObj = (value: string, key: number, firstValue = '100') => {
    const returnObj = {
      color: '',
      height: `${(parseFloat(value) * 100) / parseFloat(firstValue)}%`,
    };

    if (key === 0) {
      returnObj.color = '#7a2bdf';
      returnObj.height = '100%';
    } else if (key === 1) {
      returnObj.color = '#8a3feb';
    } else if (key === 2) {
      returnObj.color = '#8e40f3';
    } else if (key === 3) {
      returnObj.color = '#ab69ff';
    } else if (key === 4) {
      returnObj.color = '#c598ff';
    }

    return returnObj;
  };
  const [didViewCountUp, setDidViewCountUp] = useState(false);

  const onVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };

  return (
    <section id="section-fifth" className="fifth-screen">
      <div
        className="section-fifth-graphic wow fadeInRight"
        data-wow-iteration="1"
        data-wow-delay="0.5s"
        data-wow-duration="2.5s"
      ></div>
      <div className="container-fluid">
        <div className="row justify-content-center mb-300">
          <div className="col-12 col-lg-12 text-left">
            <div className="currently-holding position-relative">
              <div className="fs-210 w-100 d-flex flex-column u-upper text-primary3 mbn-50">
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="1.5s"
                >
                  IN 2021,
                </span>
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.75s"
                  data-wow-duration="2.5s"
                >
                  YOU JOINED
                </span>
              </div>
              <div className="position-relative zover">
                <h1
                  className="fs-510 text-white SuperluminaBlack text-center mbn-40 wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.75s"
                  data-wow-duration="2.5s"
                >
                  {/* {collectionStats.totalUniqueCollections} */}
                  <VisibilitySensor
                    onChange={onVisibilityChange}
                    offset={{
                      top: 10,
                    }}
                    delayedCall
                  >
                    <CountUp
                      start={0}
                      end={didViewCountUp ? collectionStats.totalUniqueCollections : 0}
                      duration={2}
                      className="text-white"
                      redraw={true}
                    />
                  </VisibilitySensor>
                </h1>
              </div>
              <div
                className="w-100 fs-210 d-flex flex-column u-upper text-right text-primary3 wow fadeInRight"
                data-wow-iteration="1"
                data-wow-delay="0.75s"
                data-wow-duration="2.5s"
              >
                <span>COLLECTIONS</span>
              </div>
            </div>
          </div>
        </div>

        {collectionStats.top5CollectionsByMostSpent &&
          collectionStats.top5CollectionsByMostSpent.length > 0 && (
            <>
              <div className="row mb-70">
                <div className="col-12 text-center">
                  <div
                    className="fs-130 d-flex flex-column u-upper wow fadeInUp"
                    data-wow-iteration="1"
                    data-wow-delay="0.75s"
                    data-wow-duration="2.5s"
                  >
                    <span>YOU SPENT THE MOST ON...</span>
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-12 text-center">
                  <ul
                    // className="nft-graph wow pulse"
                    className="nft-graph"
                    data-wow-iteration="infinite"
                    data-wow-delay="0.75s"
                    data-wow-duration="2.5s"
                  >
                    {collectionStats.top5CollectionsByMostSpent.map((obj, key) => {
                      const styleConfig = getConfigObj(
                        obj.spentAmount,
                        key,
                        collectionStats.top5CollectionsByMostSpent[0].spentAmount
                      );
                      const words = obj.collectionName.split(' ');

                      return (
                        <li className="nft-graph-items" key={key}>
                          <div
                            className="nft-graph-line"
                            style={{
                              backgroundColor: styleConfig.color,
                              height: styleConfig.height,
                            }}
                          >
                            {words.length === 1 && (
                              <div className="nft-graph-name">{obj.collectionName}</div>
                            )}
                            {words.length > 1 && (
                              <>
                                <div className="nft-graph-name row1">
                                  {words.slice(0, -1).join(' ')}
                                </div>
                                <div className="nft-graph-name row2">
                                  {words.slice(-1)}
                                </div>
                              </>
                            )}
                          </div>
                          <div className="nft-graph-count fs-40 RoobertBold d-flex flex-column">
                            <span>{obj.spentAmount}</span>
                            <span>ETH</span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </>
          )}
      </div>
    </section>
  );
};

export default SectionFive;
