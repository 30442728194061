import { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { ToastContainer } from 'react-toastify';

// import ScrollToTop from './utils/ScrollToTop';
import RouteConstants from './config/Constant';
import AppRouteComponent from './components/AppRoute';

import Homepage from '@pages/home-page/index';

interface Props {}

interface State {}

class App extends Component<Props, State> {
  render() {
    return (
      <>
        <BrowserRouter>
          {/* <ScrollToTop> */}
          <AnimatePresence>
            <Switch>
              <Route path={[RouteConstants.LANDING_PAGE]}>
                <Switch>
                  <AppRouteComponent
                    exact
                    path={RouteConstants.LANDING_PAGE}
                    component={Homepage}
                  />
                </Switch>
              </Route>
            </Switch>
          </AnimatePresence>
          {/* </ScrollToTop> */}
          <ToastContainer autoClose={false} icon={false} />
        </BrowserRouter>
      </>
    );
  }
}

export default App;
