import { AxiosResponse } from 'axios';
import BaseApi from './BaseApi';
import { IMyStatsResponse, IMintResponse } from './types/stats';

const WalletStatApi = {
  getWalletStats: async (
    walletAddress: string
  ): Promise<AxiosResponse<IMyStatsResponse>> => {
    return await BaseApi.getRequest(
      `/wallet-stats?walletAddress=${walletAddress}&disablePercentMoreThanCurrentHolders=1`
    );
  },
  getWalletStatsByToken: async (
    token: string
  ): Promise<AxiosResponse<IMyStatsResponse>> => {
    return await BaseApi.getRequest(
      `/wallet-stats-by-token?wrappedToken=${token}&disablePercentMoreThanCurrentHolders=1`
    );
  },
  mintNft: async (
    s3Key: string,
    walletAddress: string
  ): Promise<AxiosResponse<IMintResponse>> => {
    return await BaseApi.getRequest(
      `/mint-nft?s3Key=${s3Key}&walletAddress=${walletAddress}`
    );
  },
  deleteMintNft: async (walletAddress: string): Promise<AxiosResponse<IMintResponse>> => {
    return await BaseApi.getRequest(`/mint-nft-delete?walletAddress=${walletAddress}`);
  },
};

export default WalletStatApi;
