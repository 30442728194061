import { FunctionComponent } from 'react';
import NumberFormat from 'react-number-format';

import { IPortfolioStats } from '@api/types/stats';

interface Props {
  portfolioStats: null | IPortfolioStats;
}

const SectionTenth: FunctionComponent<Props> = ({ portfolioStats }: Props) => {
  return (
    <section id="section-tenth" className="tenth-screen pb-0">
      <div className="container">
        <div className="row justify-content-center mb-400">
          <div className="col-12 col-lg-12 text-center">
            <div className="currently-holding position-relative">
              <div className="fs-100 w-100 d-flex flex-column u-upper text-primary2 mb-4">
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.25s"
                  data-wow-duration="1s"
                >
                  LET’S GET SOME
                </span>
              </div>
              <h1 className="fs-240 d-flex flex-column u-upper text-offwhite SuperluminaBlack mb-5">
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.25s"
                  data-wow-duration="1s"
                >
                  BIG
                </span>
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="1.25s"
                >
                  RECAP
                </span>
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.75s"
                  data-wow-duration="1.5s"
                >
                  ENERGY
                </span>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mb-200">
          <div className="col-12">
            <div className="w-100 d-flex flex-column u-upper text-center fs-100 text-primary2">
              <span
                className="wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.25s"
                data-wow-duration="1s"
              >
                As of now,
              </span>
              <span
                className="wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.5s"
                data-wow-duration="1.25s"
              >
                your total portfolio
              </span>
              <span
                className="wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.75s"
                data-wow-duration="1.5s"
              >
                Floor value is worth
              </span>
              <span
                className="fs-240 text-white wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="1s"
                data-wow-duration="1.75s"
              >
                {portfolioStats?.totalPortfolioETH}ETH
              </span>
            </div>
          </div>
        </div>

        <div className="row mb-200">
          <div className="col-12">
            <div className="w-100 d-flex flex-column u-upper text-center fs-100 text-primary2">
              <span
                className="wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.25s"
                data-wow-duration="1s"
              >
                You sold {portfolioStats?.totalNFTsSold} NFTs
              </span>
              <span
                className="wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.5s"
                data-wow-duration="1.25s"
              >
                for a profit of
              </span>
              <span
                className="fs-240 text-white wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.75s"
                data-wow-duration="1.5s"
              >
                {portfolioStats?.totalProfitETH}ETH
              </span>
            </div>
          </div>
        </div>

        <div className="row mb-200">
          <div className="col-12">
            <div className="w-100 d-flex flex-column u-upper text-center fs-100 text-primary2">
              <span
                className="wow fadeInLeft"
                data-wow-iteration="1"
                data-wow-delay="0.25s"
                data-wow-duration="1s"
              >
                And since ETH is
              </span>
              <span
                className="wow fadeInRight"
                data-wow-iteration="1"
                data-wow-delay="0.5s"
                data-wow-duration="1.25s"
              >
                currently trading
              </span>
              <span
                className="wow fadeInLeft"
                data-wow-iteration="1"
                data-wow-delay="0.75s"
                data-wow-duration="1.5s"
              >
                at ${portfolioStats?.ethUsdPrice}...
              </span>
              <span
                className="wow fadeInRight"
                data-wow-iteration="1"
                data-wow-delay="1s"
                data-wow-duration="1.75s"
              >
                You actually made
              </span>
              <span
                className="fs-240 text-white wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="1.5s"
                data-wow-duration="2s"
              >
                <NumberFormat
                  value={portfolioStats?.totalProfitInUSD}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix="$"
                  renderText={(formattedValue: string) => {
                    return formattedValue;
                  }}
                />
              </span>
            </div>
          </div>
        </div>

        <div className="row mb-0">
          <div className="col-12">
            {portfolioStats && parseFloat(portfolioStats.totalProfitETH) > 0 && (
              <div
                className="big-round-circle wow zoomIn"
                data-wow-iteration="1"
                data-wow-delay="0.95s"
                data-wow-duration="1.75s"
              >
                <div className="big-round-circle-wrap">
                  <div className="big-round-circle-wrap-inner">
                    <div className="d-flex flex-column justify-content-center align-items-center text-center p-6">
                      <h4 className="fs-100 fw-bold text-teal">STATUS:</h4>
                      <h1 className="fs-360 fw-bold SuperluminaBlack text-primary-dark d-flex flex-column">
                        <span>GMI</span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {portfolioStats && !parseFloat(portfolioStats.totalProfitETH) && (
              <div className="big-round-circle">
                <div className="big-round-circle-wrap">
                  <div className="big-round-circle-wrap-inner">
                    <div className="d-flex flex-column justify-content-center align-items-center text-center p-6">
                      <h4 className="fs-100 fw-bold text-teal d-flex flex-column">
                        <span>DON'T WORRY.</span>
                        <span>WE STILL BELIEVE IN YOU</span>
                      </h4>
                      <h1 className="fs-360 fw-bold SuperluminaBlack text-primary-dark d-flex flex-column">
                        <span>WAG</span>
                        <span>MI</span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionTenth;
