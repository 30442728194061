import React from 'react';

interface Props {
  children: React.ReactChild | React.ReactChildren;
}

interface State {}

class UnauthenticatedLayout extends React.Component<Props, State> {
  render() {
    return <>{this.props.children}</>;
  }
}

export default UnauthenticatedLayout;
