import { FunctionComponent } from 'react';

import { IFirstTransactionStats } from '@api/types/stats';

interface Props {
  walletAddressHandler: (isAdd: boolean) => void;
  setShowDetailDiv: (value: boolean) => void;
  walletAddress: string;
  showLoader: boolean;
  firstTransactionStats?: null | IFirstTransactionStats;
  showSecondLoaderSection: boolean;
}

const SectionOne: FunctionComponent<Props> = ({
  setShowDetailDiv,
  walletAddress,
  showLoader,
  firstTransactionStats,
  showSecondLoaderSection,
}: Props) => {
  // const disconnectWallet = () => {
  //   walletAddressHandler(false);
  // };

  return (
    <section id="section-home">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-12 text-center">
            {!walletAddress && !showLoader && (
              <div
                id="gm"
                className="w-75 sm:mw-100 min-vh-100 sm:max-vh-100 u-flex flex-column align-items-center justify-content-center mx-auto"
              >
                <div className="mobile-section-home">
                  <div className="w-100 mx-auto section-home-headers">
                    <div className="section-home-headers-01">
                      <div
                        id="section1Text1"
                        className="ff-roobert-bold fs-200 fw-ultrabold h-150px wow bounceInLeft"
                        data-wow-iteration="1"
                        data-wow-delay="0.2s"
                        data-wow-duration="1s"
                      >
                        gm...
                      </div>
                    </div>
                    <div className="section-home-headers-02">
                      <div
                        id="section1Text2"
                        className="ff-roobert-bold fs-200 fw-ultrabold h-150px wow bounceInRight"
                        data-wow-iteration="1"
                        data-wow-delay="0.4s"
                        data-wow-duration="1.5s"
                      >
                        are u
                      </div>
                    </div>
                    <div className="section-home-headers-03">
                      <div
                        id="section1Text3"
                        className="ff-roobert-bold fs-200 fw-ultrabold h-150px wow bounceInLeft"
                        data-wow-iteration="1"
                        data-wow-delay="0.6s"
                        data-wow-duration="2s"
                      >
                        gunna
                      </div>
                    </div>
                    <div className="section-home-headers-04">
                      <div
                        id="section1Text4"
                        className="ff-roobert-bold fs-200 fw-ultrabold h-150px wow bounceInRight"
                        data-wow-iteration="1"
                        data-wow-delay="0.8s"
                        data-wow-duration="2.5s"
                      >
                        make it?
                      </div>
                    </div>
                  </div>
                  <div id="section1Text5" className="w-550 mx-auto">
                    <h4
                      className="ff-roobert-bold h4 text-white mb-4 wow bounceInUp"
                      data-wow-iteration="1"
                      data-wow-delay="1.5s"
                      data-wow-duration="2s"
                    >
                      Dive into your best, worst and most insane NFT moments of the
                      year...
                    </h4>
                  </div>
                </div>
                <div className="mobile-sticky-bottom">
                  {/* <button
                    className={`button button-secondary RoobertSemiBold js-connect wow bounceInUp ${
                      showLoader ? `btn-loading` : ``
                    }`}
                    data-wow-iteration="1"
                    data-wow-delay="1.5s"
                    data-wow-duration="2s"
                    onClick={() => walletAddressHandler(true)}
                  >
                    {showLoader ? 'Connecting...' : 'Connect your wallet'}
                  </button> */}
                  <a
                    href="https://metalink.com/wrapped"
                    className={`button button-secondary RoobertSemiBold js-connect wow bounceInUp`}
                    data-wow-iteration="1"
                    data-wow-delay="1.5s"
                    data-wow-duration="2s"
                  >
                    Try Again
                  </a>
                  <span
                    className="d-block d-sm-none fs-20 mt-4 wow bounceInUp"
                    data-wow-iteration="1"
                    data-wow-delay="2s"
                    data-wow-duration="2.5s"
                  >
                    Best Experienced On Desktop
                  </span>
                </div>
              </div>
            )}

            {showLoader && (
              <div
                id="woah"
                className="w-100 min-vh-100 u-flex flex-column align-items-center justify-content-center mx-auto wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.3s"
                data-wow-duration="2s"
              >
                {showSecondLoaderSection && (
                  <>
                    <div className="w-100 mobile-section-home mb-5">
                      <h1 className="fs-500 m-0">
                        <span>WOAH</span> <span>SATOSHI</span>
                      </h1>
                    </div>
                    <h5 className="fs-60 SuperluminaSemiBold opacity4 u-upper mobile-sticky-bottom">
                      <span>you've been busy this year...</span>{' '}
                      <span>Check back in a bit...</span>
                    </h5>

                    <div className="section-loading">
                      <div className="loading-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </>
                )}

                {!showSecondLoaderSection && (
                  <>
                    <div className="w-100 mobile-section-home mb-5">
                      <h1 className="fs-500 m-0">
                        <span>SIT</span> <span>TIGHT</span>
                      </h1>
                    </div>
                    <h5 className="fs-60 SuperluminaSemiBold opacity4 u-upper mobile-sticky-bottom">
                      <span>The NFT GODS ARE ANALYZING YOUR DATA...</span>
                    </h5>
                  </>
                )}
              </div>
            )}

            {walletAddress && (
              <>
                {!firstTransactionStats && (
                  <div
                    id="walletHistory"
                    className="w-100 min-vh-100 sm:max-vh-100 u-flex flex-column align-items-center justify-content-center mx-auto wow fadeInUp"
                    data-wow-iteration="1"
                    data-wow-delay="0.3s"
                    data-wow-duration="2s"
                  >
                    <div className=" mobile-section-home">
                      <div className="w-100 mb-5">
                        <h1 className="fs-205 SuperluminaLight text-primary3 mb-0">
                          <span>YOU HAVE NO</span> <span>HISTORY ON THIS</span>{' '}
                          <span>WALLET</span>
                        </h1>
                      </div>
                      <h5 className="fs-60 w-100 d-flex flex-column u-upper SuperluminaSemiBold opacity4 mb-60">
                        <span>Either you connected the wrong one (ngmi)</span>or you
                        didn't NFT at all this year (also, ngmi).
                      </h5>
                    </div>

                    <a
                      href="https://metalink.com/wrapped"
                      className="button button-tertiary js-connect mobile-sticky-bottom"
                    >
                      Connect a different wallet
                    </a>
                  </div>
                )}

                {firstTransactionStats && (
                  <div
                    id="wrappedReady"
                    className="w-100 min-vh-100 sm:max-vh-100 u-flex flex-column align-items-center justify-content-center mx-auto wow fadeInRight"
                    data-wow-iteration="1"
                    data-wow-delay="0s"
                    data-wow-duration="1s"
                  >
                    <div className="w-100 mb-70 mobile-section-home">
                      <h1 className="fs-300 SuperluminaLight text-primary3">
                        <span>YOUR</span> <span>WRAPPED</span> <span>IS</span>{' '}
                        <span>READY</span>
                      </h1>
                    </div>

                    <button
                      className="button button-tertiary js-connect mobile-sticky-bottom"
                      onClick={() => setShowDetailDiv(true)}
                    >
                      Let’s Go!
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionOne;
