import dotenv from 'dotenv';
import { AbiItem } from 'web3-utils';
import { createAlchemyWeb3 } from '@alch/alchemy-web3';
import * as SmartContractABI from '../artifacts/NFTWrapped.json';
import * as ERC20ABI from '../artifacts/ERC20.json';

dotenv.config();

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS || '';
const erc20ContractAddress = process.env.REACT_APP_ERC20TOKEN_CONTRACT_ADDRESS || '';
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY || '';

const web3 = createAlchemyWeb3(alchemyKey);

export const mintNFTUsingSOS = async (tokenId: string, tokensNeeded: number) => {
  window.tokenContract = await new web3.eth.Contract(
    ERC20ABI.abi as AbiItem[],
    erc20ContractAddress
  );

  window.contract = await new web3.eth.Contract(
    SmartContractABI.abi as AbiItem[],
    contractAddress
  );

  try {
    const wei = web3.utils.toWei(tokensNeeded.toString(), 'ether');

    const approveTransactionParameters = {
      to: erc20ContractAddress, // Required except during contract publications.
      from: window.ethereum.selectedAddress, // must match user's active address.
      value: 0,
      data: window.tokenContract.methods.approve(contractAddress, wei).encodeABI(),
    };

    /* const approveTxHash = */
    await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [approveTransactionParameters],
    });

    // console.log(approveTxHash);

    const mintAmount = 1;
    const GAS_LIMIT = 285000;
    const totalGasLimit = String(GAS_LIMIT * mintAmount);

    const mintTransactionParameters = {
      gas: web3.utils.toHex(totalGasLimit),
      to: contractAddress, // Required except during contract publications.
      from: window.ethereum.selectedAddress, // must match user's active address.
      value: 0,
      data: window.contract.methods.mintWithSOS(tokenId).encodeABI(), //make call to NFT smart contract
    };

    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [mintTransactionParameters],
    });

    return {
      success: true,
      status:
        '✅ Check out your transaction on Etherscan: <a href="https://www.etherscan.io/tx/' +
        txHash +
        '" target="_blank">View Transaction</a>',
    };
  } catch (error: any) {
    return {
      success: false,
      status: '😥 Something went wrong: ' + error.message,
    };
  }

  // return mintNFT(tokenId, 0);
};

export const mintNFT = async (tokenId: string, price: number) => {
  window.contract = await new web3.eth.Contract(
    SmartContractABI.abi as AbiItem[],
    contractAddress
  );

  const mintAmount = 1;
  const GAS_LIMIT = 285000;
  const totalGasLimit = String(GAS_LIMIT * mintAmount);

  const totalCost = price * mintAmount;
  const wei = web3.utils.toWei(totalCost.toString(), 'ether');

  const transactionParameters = {
    gas: web3.utils.toHex(totalGasLimit),
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    value: web3.utils.toHex(wei),
    data: window.contract.methods.mint(tokenId).encodeABI(), //make call to NFT smart contract
  };

  //sign the transaction via Metamask
  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        '✅ Check out your transaction on Etherscan: <a href="https://www.etherscan.io/tx/' +
        txHash +
        '" target="_blank">View Transaction</a>',
    };
  } catch (error: any) {
    return {
      success: false,
      status: '😥 Something went wrong: ' + error.message,
    };
  }
};

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      const obj = {
        status: '',
        address: addressArray[0],
      };
      return obj;
    } catch (err: any) {
      return {
        address: '',
        status: '😥 ' + err.message,
      };
    }
  } else {
    return {
      address: '',
      status:
        '🦊 ' + 'You must install Metamask, a virtual Ethereum wallet, in your browser.',
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_accounts',
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: '',
        };
      } else {
        return {
          address: '',
          status: '',
        };
      }
    } catch (err: any) {
      return {
        address: '',
        status: '😥 ' + err.message,
      };
    }
  } else {
    return {
      address: '',
      status:
        '🦊 ' + 'You must install Metamask, a virtual Ethereum wallet, in your browser.',
    };
  }
};
