import { FunctionComponent, useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import { IQuickestFlipStats } from '@api/types/stats';

interface Props {
  quickestFlipStats: null | IQuickestFlipStats;
}

const SectionEight: FunctionComponent<Props> = ({ quickestFlipStats }: Props) => {
  const [didViewCountUp, setDidViewCountUp] = useState(false);

  const onVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };

  if (!quickestFlipStats) {
    return null;
  }

  return (
    <section id="section-eighth" className="eighth-screen">
      <div className="container-fluid">
        <div className="row justify-content-center mb-300">
          <div className="col-12 col-lg-12 text-left">
            <div className="currently-holding position-relative">
              <div className="fs-510 w-100 d-flex flex-column u-upper text-warning1 text-left">
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.25s"
                  data-wow-duration="1s"
                >
                  YOUR
                </span>
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="1.25s"
                >
                  QUICKEST
                </span>
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.75s"
                  data-wow-duration="1.5s"
                >
                  FLIP...
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mb-0">
          <div className="col-12 col-lg-12 text-center">
            {quickestFlipStats?.tokenImage && (
              <div className="d-flex flex-column justify-content-center align-items-start">
                <picture
                  className="size-220 m-0 p-0 ml-15 mbn-60 wow fadeInLeft"
                  data-wow-iteration="1"
                  data-wow-delay="0.75s"
                  data-wow-duration="1.5s"
                >
                  <img
                    src={quickestFlipStats?.tokenImage}
                    alt=""
                    className="img-fluid styled-image rotate30n"
                  />
                </picture>
              </div>
            )}
            <h1 className="fs-250 text-truncate text-white d-flex flex-column u-upper">
              <span
                className="wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.25s"
                data-wow-duration="1s"
              >
                {quickestFlipStats?.collectionName}
              </span>
              <span
                className="wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.5s"
                data-wow-duration="1.25s"
              >
                #{quickestFlipStats?.tokenId}
              </span>
            </h1>
          </div>
        </div>
        <div className="row justify-content-center mb-250">
          <div className="col-12 col-lg-8 text-center">
            <div className="d-flex w-90 mx-auto blb flex-column justify-content-center align-items-end">
              <div className="transitionLine"></div>
              <div
                className="d-flex flex-column fs-40 pt-5 text-left text-warning2 wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.25s"
                data-wow-duration="1s"
              >
                <span className="w-100">bought for {quickestFlipStats?.amount}ETH</span>
                <span className="w-100">on {quickestFlipStats?.boughtOn}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mb-250">
          <div className="col-12 col-lg-11 text-center zover">
            <div className="d-flex align-items-center justify-content-center flex-row text-center">
              <div
                className="fs-300 SuperluminaBlack text-white mr-5 wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.25s"
                data-wow-duration="1s"
              >
                {/* {quickestFlipStats?.heldDays} */}
                <VisibilitySensor
                  onChange={onVisibilityChange}
                  offset={{
                    top: 10,
                  }}
                  delayedCall
                >
                  <CountUp
                    start={0}
                    end={
                      didViewCountUp
                        ? quickestFlipStats
                          ? parseFloat(quickestFlipStats.heldDays)
                          : 0
                        : 0
                    }
                    duration={1}
                    className="text-white"
                    redraw={true}
                  />
                </VisibilitySensor>
              </div>
              <div className="d-flex flex-column text-left fs-120 text-warning2">
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="1.25s"
                >
                  DAYS
                </span>
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.75s"
                  data-wow-duration="1.5s"
                >
                  HELD
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mb-250">
          <div className="col-12 col-lg-11 text-center zover">
            <div className="d-flex align-items-center justify-content-center flex-row text-center text-truncate">
              <div className="d-flex flex-column text-left fs-120 text-warning2">
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="1.25s"
                >
                  SOLD
                </span>
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.75s"
                  data-wow-duration="1.5s"
                >
                  FOR
                </span>
              </div>
              <div
                className="fs-240 SuperluminaBlack text-white ml-5 wow fadeInUp text-truncate"
                data-wow-iteration="1"
                data-wow-delay="0.5s"
                data-wow-duration="1.25s"
              >
                {quickestFlipStats?.soldAmount}ETH
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mbn-80 zover">
          <div className="col-12 col-lg-11 text-center zover">
            <div className="d-flex align-items-center justify-content-center sm:flex-col flex-row text-center text-truncate">
              <div
                className="fs-200 SuperluminaBlack text-white mr-5 wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.5s"
                data-wow-duration="1.25s"
              >
                {quickestFlipStats?.profit}ETH
              </div>
              <div className="d-flex flex-column text-left fs-80 text-warning2">
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="1.25s"
                >
                  TOTAL
                </span>
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.75s"
                  data-wow-duration="1.5s"
                >
                  PROFIT
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionEight;
