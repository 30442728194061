import { FunctionComponent, useState } from 'react';
import NumberFormat from 'react-number-format';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import { ITotalStats, ITotalGasStats } from '@api/types/stats';

import ElonMusk from '@assets/images/ElonMusk.png';

interface Props {
  totalStats: ITotalStats;
  totalGasStats: ITotalGasStats;
}

const SectionFour: FunctionComponent<Props> = ({ totalStats, totalGasStats }: Props) => {
  const [didViewCountUp, setDidViewCountUp] = useState(false);

  const onVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };

  return (
    <section id="section-four" className="fourth-screen">
      <div className="container">
        <div className="row justify-content-center mb-150">
          <div className="col-12 col-lg-12 text-center">
            <div className="currently-holding position-relative">
              <div className="fs-120 w-100 d-flex flex-column u-upper text-primary2 mb-4">
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.1s"
                  data-wow-duration="1s"
                >
                  CURRENTLY
                </span>
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.2s"
                  data-wow-duration="1.5s"
                >
                  HODLING
                </span>
              </div>
              <h1
                className="fs-510 text-primary-dark SuperluminaBlack mb-5 wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.3s"
                data-wow-duration="2s"
              >
                <VisibilitySensor
                  onChange={onVisibilityChange}
                  offset={{
                    top: 10,
                  }}
                  delayedCall
                >
                  <CountUp
                    start={0}
                    end={didViewCountUp ? totalStats.holdsCount : 0}
                    duration={2}
                    className="text-primary-dark"
                    redraw={true}
                  />
                </VisibilitySensor>
              </h1>

              <div
                className="fs-120 text-center u-upper text-primary2 wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.35s"
                data-wow-duration="2.5s"
              >
                <span className="m-block">TRUE</span>{' '}
                <span className="m-block">DEGEN</span>{' '}
                <span className="m-block">VIBES</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {parseFloat(totalGasStats.gasPriceInEth) > 0 && (
        <>
          <div className="container-fluid">
            <div className="row mb-150">
              <div className="col-12 mb-20">
                <div
                  className="w-100 text-center fs-62 wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="2.5s"
                >
                  ⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽
                  ⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️⛽️
                </div>
              </div>
            </div>
            <div className="row mb-70">
              <div className="col-12 text-center">
                <div
                  className="fs-130 u-upper wow bounceInLeft"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="2.5s"
                >
                  <span>BUT LET’S NOT GET</span> <span>GASSED UP...</span>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row mb-150">
              <div className="col-12 col-lg-6 col-md-6">
                <div
                  className="position-relative overflow-hidden big-rounded-box wow bounceInDown"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="2.5s"
                  style={{ backgroundColor: '#656FC9' }}
                >
                  <div className="position-absolute h-100 w-100 text-center d-flex align-items-center justify-content-end flex-column">
                    <div
                      className="big-box-text fs-600 SuperluminaRegular"
                      style={{ color: '#5661C2' }}
                    >
                      {totalGasStats.gasPriceInEth}
                    </div>
                    <div className="big-box-body d-flex align-items-center justify-content-center flex-column">
                      <div
                        className="w-100 d-flex flex-column fs-100"
                        style={{ color: '#F3EEA7' }}
                      >
                        <span>YOU SPENT</span>
                        <span>{totalGasStats.gasPriceInEth}ETH ON GAS!</span>
                      </div>
                    </div>
                    <div className="big-box-footer d-flex align-items-end justify-content-center">
                      That’s about{' '}
                      <NumberFormat
                        value={totalGasStats.gasPriceInUSD}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix="$"
                        renderText={(formattedValue: string) => {
                          return formattedValue;
                        }}
                      />{' '}
                      today...
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-6 col-md-6">
                <div
                  className="position-relative overflow-hidden big-rounded-box wow bounceInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="2.5s"
                  style={{ backgroundColor: '#8AB39B' }}
                >
                  <div className="position-absolute h-100 w-100 text-center d-flex align-items-center justify-content-center flex-column">
                    <div
                      className="big-box-text fs-600 SuperluminaRegular"
                      style={{ color: '#719F84' }}
                    >
                      OOOF
                    </div>
                    <div className="big-box-body d-flex align-items-center justify-content-center flex-column">
                      <div className="w-100 d-flex flex-column">
                        <picture
                          className="size-250 m-0 p-0 mx-auto wow zoomIn"
                          data-wow-iteration="1"
                          data-wow-delay="0.75s"
                          data-wow-duration="3s"
                        >
                          <img src={ElonMusk} className="img-fluid" alt="Elon Musk" />
                        </picture>
                      </div>
                    </div>
                    <div className="big-box-footer d-flex align-items-end justify-content-center">
                      Instead of gas you could have bought {totalGasStats.numTesla}{' '}
                      Teslas...
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mbn-80">
              <div className="col-12">
                <div
                  className="w-100 text-center fs-420 text-primary3 wow fadeInRight"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="2.5s"
                >
                  OOOOOOOOF
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {parseFloat(totalGasStats.gasPriceInEth) <= 0 && (
        <>
          <div className="container-fluid">
            <div className="row mb-70 mt-200">
              <div className="col-12 text-center">
                <div
                  className="fs-130 d-flex flex-column u-upper wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="2.5s"
                >
                  <span>TIME TO GET GASSED UP!</span>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row mb-150">
              <div className="col-12 col-lg-6 col-md-6">
                <div
                  className="position-relative overflow-hidden big-rounded-box wow bounceInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="2.5s"
                  style={{ backgroundColor: '#656FC9' }}
                >
                  <div className="position-absolute h-100 w-100 text-center d-flex align-items-center justify-content-end flex-column">
                    <div
                      className="big-box-text fs-600 SuperluminaRegular"
                      style={{ color: '#5661C2' }}
                    >
                      0ETH
                    </div>
                    <div className="big-box-body d-flex align-items-center justify-content-center flex-column">
                      <div
                        className="w-100 d-flex flex-column fs-100"
                        style={{ color: '#F3EEA7' }}
                      >
                        <span>YOU SPENT</span>
                        <span>0ETH ON GAS</span>
                        <span>THIS YEAR!</span>
                      </div>
                    </div>
                    <div className="big-box-footer d-flex align-items-end justify-content-center"></div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-6 col-md-6">
                <div
                  className="position-relative overflow-hidden big-rounded-box wow bounceInDown"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="2.5s"
                  style={{ backgroundColor: '#8AB39B' }}
                >
                  <div className="position-absolute h-100 w-100 text-center d-flex align-items-center justify-content-center flex-column">
                    <div
                      className="big-box-text fs-600 SuperluminaRegular"
                      style={{ color: '#719F84' }}
                    >
                      LFG!
                    </div>
                    <div className="big-box-body d-flex align-items-center justify-content-center flex-column">
                      <div className="w-100 d-flex flex-column"></div>
                    </div>
                    <div className="big-box-footer d-flex align-items-end justify-content-center">
                      But wait...
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mbn-80">
              <div className="col-12">
                <div
                  className="w-100 position-relative wow bounceInLeft"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="2.5s"
                >
                  <div className="type-icon fs-100">😅</div>
                  <div className="w-100 text-center fs-420 SuperluminaLight text-primary3">
                    Do You Even NFT?
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default SectionFour;
