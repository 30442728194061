import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';

import config from './config/config';

import './assets/css/vendors.css';
import './assets/css/animate/animate.css';
import './assets/css/default.css';
import './assets/css/mobile@767.css';
import './assets/css/mobile@992.css';
import 'animate.css';
import 'react-toastify/dist/ReactToastify.css';

// Sentry Tracking
if (config.ENABLE_SENTRY) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: process.env.REACT_APP_STAGE,
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
