const development = {
  BASE_URL: 'http://localhost:3000',
  apiGateway: {
    BASE_URL: 'https://nftwrappedapi.paperhands.gg',
    // BASE_URL: 'http://localhost:3000',
  },
  GA_TRACKING_ID: 'G-19M0FTTGTS',
  ENABLE_SENTRY: false,
};

const production = {
  BASE_URL: 'https://wrapped.metalink.com',
  apiGateway: {
    BASE_URL: 'https://nftwrappedapi.paperhands.gg',
  },
  GA_TRACKING_ID: 'G-19M0FTTGTS',
  ENABLE_SENTRY: false,
};

let baseConfig = process.env.REACT_APP_STAGE === 'production' ? production : development;

const AppConfig = {
  REACT_APP_NAME: 'NFT Wrapped',
  REACT_APP_STAGE: process.env.REACT_APP_STAGE,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  REACT_APP_VERSION: 1,
  ...baseConfig,
};

export default AppConfig;
