import { FunctionComponent, useEffect } from 'react';

import { IFirstTransactionStats } from '@api/types/stats';

import Dollar from '@assets/images/Dollar.png';

interface Props {
  firstTransactionStats: null | IFirstTransactionStats;
}

const SectionTwo: FunctionComponent<Props> = ({ firstTransactionStats }: Props) => {
  useEffect(() => {
    if (!firstTransactionStats) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [firstTransactionStats]);

  const getStatus = () => {
    let returnObj = (
      <>
        <span>DIAMOND</span>
        <span>HANDS!</span>
      </>
    );

    if (firstTransactionStats?.isSold) {
      returnObj = (
        <>
          <span>SECURED</span>
          <span>THE BAG</span>
        </>
      );

      if (
        firstTransactionStats?.soldTransaction &&
        parseFloat(firstTransactionStats?.soldTransaction.profit) < 0
      ) {
        returnObj = <span>FUMBLED</span>;
      }

      if (
        firstTransactionStats?.soldTransaction &&
        parseFloat(firstTransactionStats?.soldTransaction.profit) === 0
      ) {
        returnObj = <span className="fs-240">MAINTAINING</span>;
      }
    }

    return returnObj;
  };

  return (
    <section id="section-two" className="second-screen">
      <div className="container">
        <div className="row justify-content-center mb-250">
          <div className="col-12 col-lg-12 text-center">
            <h1
              className="fs-150 text-white d-flex flex-column u-upper wow fadeInUp"
              data-wow-iteration="1"
              data-wow-delay="0.3s"
              data-wow-duration="2s"
            >
              <span className="w-100 text-center">2021 WAS A</span>
              <span className="w-100 text-center">BREAKOUT YEAR</span>
              <span className="w-100 text-center">FOR NFTS</span>
            </h1>
          </div>
        </div>

        <div className="row justify-content-center mb-250">
          <div className="col-12 col-lg-12 text-center">
            <h1
              className="fs-150 text-white d-flex flex-column u-upper wow fadeInUp"
              data-wow-iteration="1"
              data-wow-delay="0.35s"
              data-wow-duration="2.5s"
            >
              <span className="w-100 text-center">YOU WERE PART OF</span>
              <span className="w-100 text-center">MAKING THAT</span>
              <span className="w-100 text-center">HAPPEN</span>
            </h1>
          </div>
        </div>

        <div className="row justify-content-center mb-250">
          <div className="col-12 col-lg-12 text-center">
            <h1
              className="fs-150 text-white d-flex flex-column u-upper wow fadeInUp"
              data-wow-iteration="1"
              data-wow-delay="0.35s"
              data-wow-duration="2.75s"
            >
              <span className="w-100 text-center">LET'S SEE HOW</span>
              <span className="w-100 text-center">YOU DID</span>
            </h1>
          </div>
        </div>
      </div>
      <div className="section-two-graphic"></div>
      <div className="container">
        <div className="row justify-content-center mb-250">
          <div className="col-12 col-lg-12 text-center">
            <h1
              className="fs-150 text-white d-flex flex-column u-upper wow fadeInUp"
              data-wow-iteration="1"
              data-wow-delay="0.35s"
              data-wow-duration="2.5s"
            >
              <span className="w-100 text-center">IN 2021, YOUR</span>
              <span className="w-100 text-center">
                <span className="fs-150-stroke">FIRST NFT</span> WAS...
              </span>
            </h1>
          </div>
        </div>

        <div className="row justify-content-center mb-0">
          <div className="col-12 col-lg-12 text-left">
            {firstTransactionStats?.tokenImage && (
              <div className="d-flex flex-column justify-content-center align-items-end">
                <picture
                  className="size-220 m-0 p-0 mr-16 mbn-60 zover wow fadeInRight"
                  data-wow-iteration="1"
                  data-wow-delay="0.25s"
                  data-wow-duration="1.5s"
                >
                  <img
                    src={firstTransactionStats?.tokenImage}
                    alt=""
                    className="img-fluid styled-image rotate30n"
                  />
                </picture>
              </div>
            )}
            <h1
              className="fs-250 text-truncate text-white d-flex flex-column u-upper wow fadeInLeft"
              data-wow-iteration="1"
              data-wow-delay="0.3s"
              data-wow-duration="2.25s"
            >
              {firstTransactionStats?.collectionName} <br />#
              {firstTransactionStats?.tokenId}
            </h1>
          </div>
        </div>

        <div className="row justify-content-center mb-250">
          <div className="col-12 col-lg-8 text-center">
            <div
              className="d-flex w-100 blb flex-column justify-content-center align-items-end wow fadeInRight"
              data-wow-iteration="1"
              data-wow-delay="0.3s"
              data-wow-duration="2.25s"
            >
              <div className="transitionLine"></div>
              <div className="d-flex flex-column fs-40 pt-5">
                {firstTransactionStats?.amount &&
                parseFloat(firstTransactionStats?.amount) > 0 ? (
                  <span className="w-100 text-right">
                    bought for {firstTransactionStats?.amount}ETH
                  </span>
                ) : (
                  <span className="w-100 text-right">Received for free</span>
                )}

                <span className="w-100 text-right">
                  on {firstTransactionStats?.boughtOn}
                </span>
              </div>
            </div>
          </div>
        </div>

        {!firstTransactionStats?.isSold && (
          <div className="row mb-300">
            <div className="col-12 col-lg-12 text-right">
              <div
                className="fs-100 u-upper wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.3s"
                data-wow-duration="2.25s"
              >
                <>
                  You held it for{' '}
                  <span className="text-white">
                    {firstTransactionStats?.heldDaysNotSold} Days
                  </span>
                </>
                ...
              </div>
            </div>
          </div>
        )}

        {firstTransactionStats?.soldTransaction && (
          <div className="row mb-300">
            <div className="col-12 col-lg-12 text-right">
              <div
                className="fs-100 u-upper wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.3s"
                data-wow-duration="2.25s"
              >
                {firstTransactionStats.isSold && (
                  <>
                    You held it for{' '}
                    <span className="text-white">
                      {firstTransactionStats.soldTransaction.heldDays} Days
                    </span>
                  </>
                )}
                {!firstTransactionStats.isSold && (
                  <>
                    You've been holding for{' '}
                    <span className="text-white">
                      {firstTransactionStats.soldTransaction.heldDays} Days
                    </span>
                  </>
                )}
                ...
              </div>
            </div>
          </div>
        )}

        <div className="row align-items-center mb-300 position-relative">
          <div className="col-12 col-lg-12 text-left">
            {firstTransactionStats?.isSold && (
              <div className="">
                <picture
                  className="size-170 m-0 p-0 ml-20 mbn-50 wow fadeInLeft"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="3.5s"
                >
                  <img src={Dollar} alt="Dollar" className="img-fluid" />
                </picture>
                <div
                  className="fs-100 u-upper wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.3s"
                  data-wow-duration="2.25s"
                >
                  {firstTransactionStats?.isSold &&
                    firstTransactionStats?.soldTransaction &&
                    parseFloat(firstTransactionStats?.soldTransaction.amount) > 0 && (
                      <>
                        and sold it for{' '}
                        <span className="text-white">
                          {firstTransactionStats?.soldTransaction.amount} ETH
                        </span>
                        ...
                      </>
                    )}
                  {firstTransactionStats?.isSold &&
                    firstTransactionStats?.soldTransaction &&
                    parseFloat(firstTransactionStats?.soldTransaction.amount) === 0 && (
                      <>and transferred it out ...</>
                    )}
                </div>
              </div>
            )}

            {!firstTransactionStats?.isSold && (
              <div className="">
                <div
                  className="fs-100 u-upper wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.3s"
                  data-wow-duration="2.25s"
                >
                  The current floor price is{' '}
                  <span className="text-white">
                    {firstTransactionStats?.collectionFloorPrice} ETH
                  </span>
                  ...
                </div>
              </div>
            )}
            {firstTransactionStats?.isSold && firstTransactionStats?.soldTransaction && (
              <>
                <div
                  className="w-50 blb mx-auto wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.3s"
                  data-wow-duration="3.25s"
                >
                  <div className="transitionLine2"></div>
                  <div className="d-flex flex-column fs-40 pt-5 repeat">
                    <span className="w-100 text-right">
                      on {firstTransactionStats?.soldTransaction.soldOn}
                    </span>
                  </div>
                </div>
                {firstTransactionStats?.tokenImage && (
                  <picture
                    className="duck-graphic wow fadeInRight"
                    data-wow-iteration="1"
                    data-wow-delay="0.5s"
                    data-wow-duration="1.5s"
                  >
                    <img
                      src={firstTransactionStats?.tokenImage}
                      alt=""
                      className="w-100 img-fluid styled-image"
                    />
                  </picture>
                )}
              </>
            )}
          </div>
        </div>

        {firstTransactionStats?.isSold && firstTransactionStats?.soldTransaction && (
          <div className="row mb-300">
            <div className="col-12 col-lg-12 text-right">
              <div
                className="fs-100 u-upper wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.3s"
                data-wow-duration="3.25s"
              >
                {parseFloat(firstTransactionStats?.soldTransaction.profit) >= 0 ? (
                  <>
                    YOU MADE A PROFIT OF{' '}
                    <span className="text-white">
                      {firstTransactionStats?.soldTransaction.profit}ETH
                    </span>
                    ...
                  </>
                ) : (
                  <>
                    Total loss was{' '}
                    <span className="text-white">
                      {firstTransactionStats?.soldTransaction.profit}ETH
                    </span>
                    ...
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-12 col-lg-12 text-center">
            <div
              className="big-round-circle wow zoomIn"
              data-wow-iteration="1"
              data-wow-delay="0.2s"
              data-wow-duration="0.5s"
            >
              <div className="big-round-circle-wrap">
                <div className="big-round-circle-wrap-inner">
                  <div className="d-flex flex-column justify-content-center align-items-center text-center p-6">
                    <h4 className="fs-100 fw-bold text-teal">STATUS:</h4>
                    <h1 className="fs-360 fw-bold text-primary-dark d-flex flex-column">
                      {getStatus()}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionTwo;
