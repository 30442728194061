import { FunctionComponent } from 'react';

import { ILongestHoldStats } from '@api/types/stats';

interface Props {
  longestHoldStats: null | ILongestHoldStats;
}

const SectionNine: FunctionComponent<Props> = ({ longestHoldStats }: Props) => {
  return (
    <section id="section-ninth" className="ninth-screen">
      <div className="container-fluid">
        <div className="row justify-content-center mb-300">
          <div className="col-12 col-lg-12 text-left">
            <div className="currently-holding position-relative">
              <div className="fs-510 w-100 d-flex flex-column u-upper text-warning3 text-right">
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.25s"
                  data-wow-duration="1s"
                >
                  YOUR
                </span>
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="1.25s"
                >
                  LONGEST
                </span>
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.75s"
                  data-wow-duration="1.5s"
                >
                  HODL...
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mb-250">
          <div className="col-12 col-lg-11 text-center">
            {longestHoldStats?.tokenImage && (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <picture className="size-220 m-0 p-0 mbn-60">
                  <img
                    src={longestHoldStats?.tokenImage}
                    alt=""
                    className="img-fluid styled-image wow fadeInRight"
                    data-wow-iteration="1"
                    data-wow-delay="0.75s"
                    data-wow-duration="1.5s"
                  />
                </picture>
              </div>
            )}
            <h1 className="fs-250 text-white d-flex flex-column u-upper text-truncate">
              <span
                className="wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.25s"
                data-wow-duration="1s"
              >
                {longestHoldStats?.collectionName}
              </span>
              <span
                className="text-truncate wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.5s"
                data-wow-duration="1.25s"
              >
                #{longestHoldStats?.tokenId}
              </span>
            </h1>
          </div>
        </div>

        <div className="row justify-content-center sm:mmt-40 mb-50">
          <div className="col-12 col-lg-11 text-center zover">
            <div className="d-flex align-items-center justify-content-center sm:flex-col flex-row text-center">
              <div
                className="fs-250 SuperluminaBlack text-white mr-5 wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.25s"
                data-wow-duration="1s"
              >
                {longestHoldStats?.heldDays}
              </div>
              <div className="d-flex flex-column text-left fs-100 text-warning2">
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.5s"
                  data-wow-duration="1.25s"
                >
                  DAYS
                </span>
                <span
                  className="wow fadeInUp"
                  data-wow-iteration="1"
                  data-wow-delay="0.75s"
                  data-wow-duration="1.5s"
                >
                  HELD
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mb-250">
          <div className="col-12 col-lg-8 text-center">
            <div className="d-flex w-95 mx-auto blb flex-column justify-content-center align-items-end">
              <div className="transitionLine"></div>
              <div
                className="d-flex flex-column fs-40 pt-5 text-left text-warning2 wow fadeInUp"
                data-wow-iteration="1"
                data-wow-delay="0.25s"
                data-wow-duration="1s"
              >
                <span className="w-100">bought for {longestHoldStats?.amount}ETH</span>
                <span className="w-100">on {longestHoldStats?.boughtOn}</span>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row justify-content-center">
          <div className="col-12 col-lg-11 text-center zover">
            <div className="d-flex align-items-center justify-content-center flex-row text-center">
              <div className="d-flex flex-column text-center fs-105 text-warning2">
                <span className="wow fadeInUp" data-wow-iteration="1" data-wow-delay="0.25s" data-wow-duration="1s">That's longer than</span>
                <span className="wow fadeInUp" data-wow-iteration="1" data-wow-delay="0.5s" data-wow-duration="1.25s">
                  <samp className="text-white">10%</samp> of current
                </span>
                <span className="wow fadeInUp" data-wow-iteration="1" data-wow-delay="0.75s" data-wow-duration="1.5s">Cryptopunk owners</span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default SectionNine;
